import { Controller } from "stimulus"
import InfiniteScroll from "infinite-scroll"

export default class extends Controller {
  connect() {
    let paginationNextSelector = 'pagination-next'
    if (document.getElementsByClassName(paginationNextSelector).length > 0) {
      let infScroll = new InfiniteScroll('.pagination__container', {
        path: '.' + paginationNextSelector,
        append: '.pagination__item',
        hideNav: '[role="pagination"]',
        history: false
      })
    }
  }
}
