import $ from "jquery"
import Isotope from "isotope-layout"
import imagesLoaded from "imagesloaded"
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const grid = document.querySelector('.IsotopeTeam')
    let controller = this

    imagesLoaded(grid, function() {

      new Isotope(grid, {
        itemSelector: '.Team__item',
        masonry: {
          columnWidth: '.Team__item'
        }
      })

      window.allVisible = JSON.parse(grid.dataset.allVisible);
      window.allInvisible = JSON.parse(grid.dataset.allInvisible);

      const countOfVisibles = allVisible.length;

      let oldrandomVisibleIndex = 0,
          image = '';

      window.allVisibleIds = [];
      $.each(allVisible, function() {
        allVisibleIds.push(this.id);
      })

      window.setInterval(() => {
        // get transition indexes
        let randomVisibleIndex = controller.getRandomArbitrary(0, countOfVisibles - 1);
        if (randomVisibleIndex === oldrandomVisibleIndex) {
          randomVisibleIndex = controller.getRandomArbitrary(0, countOfVisibles - 1);
        }
        oldrandomVisibleIndex = randomVisibleIndex;
        const randomInvisibleIndex = controller.getRandomInvisibleIndex();

        const ProfileNew = allInvisible[randomInvisibleIndex];

        // remove current visible
        const allVisibleIdsIndexToRemove = allVisibleIds.indexOf(allVisible[randomVisibleIndex].id);
        allVisibleIds.splice(allVisibleIdsIndexToRemove, 1);
        allInvisible[randomInvisibleIndex] = allVisible[randomVisibleIndex];

        // add invisible to visble
        allVisibleIds.push(ProfileNew.id);
        allVisible[randomVisibleIndex] = ProfileNew;

        const PersonToContainer = $('#Person-' + (randomVisibleIndex + 1));

        if (PersonToContainer.hasClass('Team__item--big')) {
          image = ProfileNew.imgBig;
        } else {
          image = ProfileNew.imgSmall;
        }

        if (PersonToContainer.hasClass('active')) {
          // is currently turned on backside
          PersonToContainer.find('.Team__item__front img').attr('src', image);
          PersonToContainer.removeClass('active');
        } else {
          // is currently turned on front
          PersonToContainer.find('.Team__item__back img').attr('src', image);
          PersonToContainer.addClass('active');
        }
      }, 1500);

      // debug duplicates
      // window.setInterval(() => {
      //   console.log(controller.containsDuplicates(allVisibleIds));
      // },500)

    })

  }

  getRandomInvisibleIndex() {
    let index = -1;
    let id = 0;

    do {
      index = this.getRandomArbitrary(0, allInvisible.length - 1);
      id = allInvisible[index].id;
    }
    while (allVisibleIds.indexOf(id) != -1);

    return index;
  }

  containsDuplicates(a) {
    for (let i = 0; i < a.length; i++) {
      if (a.indexOf(a[i]) !== a.lastIndexOf(a[i])) {
        return true;
      }
    }
    return false;
  }

  getRandomArbitrary(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
  }
}

