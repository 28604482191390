import $ from 'jquery'
import 'slick-carousel'

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    this.refs = {
      arrowPrev: this.element.querySelector('.QuoteSlider__arrow-prev'),
      arrowNext: this.element.querySelector('.QuoteSlider__arrow-next')
    }

    $(this.element.querySelector('.QuoteSlider__container')).slick({
      centerMode: true,
      arrows: false,
      centerPadding: '0px',
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      //fade: true,
      adaptiveHeight: false,
      prevArrow: this.refs.arrowPrev,
      nextArrow: this.refs.arrowNext,
      pauseOnHover: false,
      speed: 1500,
      cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
    })
  }
}
