import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    this.refs = {
      arrowPrev: this.element.querySelector('.IconSlider__arrow-prev'),
      arrowNext: this.element.querySelector('.IconSlider__arrow-next'),
      container: this.element.querySelector('.IconSlider__container'),
      vivus: []
    }

    //adjust height
    $('.adjust-height-wrapper').each(function() {
      for (let counter = 0; counter < 5; counter++) {
        const element = $(this).find('.adjust-height-' + counter);

        let height = 0;
        element.each(function() {
          //TODO: var for $(this)
          $(this).css('height', 'auto');
          if (height < $(this).outerHeight()) {
            height = $(this).outerHeight();
          }
        });

        if (height > 0) {
          $(this)
          .find('.adjust-height-' + counter)
          .css('height', height + 'px');
        }
      }
    });

    //set vivus
    const svgs = this.element.querySelectorAll('svg');
    let svgId;

    for (let i = 0; i < svgs.length; ++i) {
      svgId = svgs[i].getAttribute('id');
      // this.refs.vivus[i] = new Vivus(svgId, {
      //     start: 'manual',
      //     type: 'delayed',
      //     duration: 100,
      //     animTimingFunction: Vivus.EASE
      // });

      this.refs.vivus[i] = svgId;
      svgs[i].style.visibility = 'visible';
    }

    $(this.refs.container).on('init', () => {
      if (this.refs.vivus[0] != null) {
        this.showSVG(this.refs.vivus[0]);
      }
    });

    $(this.refs.container).on('reinit', () => {
      if (this.refs.vivus[0] != null) {
        this.showSVG(this.refs.vivus[0]);
      }
    });

    $(this.refs.container).slick({
      centerMode: true,
      arrows: false,
      centerPadding: '0px',
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1500,
      fade: true,
      adaptiveHeight: true,
      prevArrow: this.refs.arrowPrev,
      nextArrow: this.refs.arrowNext,
      pauseOnHover: false,
      cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
    });

    $(this.refs.container).on('beforeChange', (slick, currentSlide) => {
      if (this.refs.vivus[currentSlide.currentSlide] != null) {
        // this.refs.vivus[currentSlide.currentSlide].stop().reset();
        // this.refs.vivus[currentSlide.currentSlide].stop().play(-3);
        this.hideSVG(this.refs.vivus[currentSlide.currentSlide]);
      }
    });

    $(this.refs.container).on('afterChange', (slick, currentSlide) => {
      if (this.refs.vivus[currentSlide.currentSlide] != null) {
        this.showSVG(this.refs.vivus[currentSlide.currentSlide]);
      }
    });
  }

  hideSVG(vivus) {
    return vivus;
  }

  showSVG(vivus) {
    return vivus;
  }
}
