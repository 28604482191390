import $ from "jquery"
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    $(document).ready(function () {
      $('.toggleClients').click(function () {
        $('.Clients').toggleClass('show-clients')
        
        if (!$('.Clients').hasClass('show-clients')) {
          var anchor = $(this).data('anchor')
          $('html, body').animate({
            scrollTop: $('#' + anchor).offset().top
          }, 1000)
        }
        
      })
    })

  }
}
