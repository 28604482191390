import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
   this.state = {
      activeIndex: 0
    }

    this.refs = {
      tabs: [...this.element.querySelector('.Locations__names').children],
      content: [...this.element.querySelector('.Locations__content').children]
    }

    this.setupListeners();
    this.render();
  }

  setupListeners() {
    this.refs.tabs.forEach((item, i) => {
      item.addEventListener('click', () => {
        this.onTabClick(i);
      });
    });
  }

  onTabClick(activeIndex) {
    this.setState({activeIndex: activeIndex});
  }

  setState(newState) {
    const prevState = this.state;
    this.state = Object.assign({}, this.state, newState);
    this.render(prevState);
  }

  render() {
    this.refs.tabs.forEach((item, i) => {
      if (this.state.activeIndex == i) {
        item.classList.add('Locations__name--active');
      } else {
        item.classList.remove('Locations__name--active');
      }
    });

    this.refs.content.forEach((item, i) => {
      if (this.state.activeIndex == i) {
        item.classList.remove('Locations__address--hidden');
      } else {
        item.classList.add('Locations__address--hidden');
      }
    });
  }
}
