/* eslint no-console:0 */

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import $ from 'jquery'
import ScrollReveal from 'scrollreveal'
import Modernizr from 'modernizr'

window.jQuery = $;
window.$ = $;

const application = Application.start()
const context = require.context("../src/javascript/controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

$(function() {

  $('.toggle-mobileMenu').click(function() {
    $('.burger__line').toggleClass('burger__line--cross');
    $('.menu-mobile').fadeToggle();
    $('html, body').toggleClass('open-mobileMenu');
  });

  if (!Modernizr.objectfit) {
    $('.PageHeader__bgImageWrapper').each(function () {
        var $container = $(this),
            imgUrl = $container.find('img').prop('src');
        if (imgUrl) {
            $container
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass('compat-object-fit');
        }
    });
  }

  $('a[href*="#"]:not([href="#"])').click(function (e) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

      //ignore ex bootstrab tabs
      if (target.selector == '#an-anchor') {
        return;
      }

      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 200);
        return false;
      }
    }
  })

  const sr = new ScrollReveal({reset: false});
  sr.reveal('.sr--fade', {
    scale: 0,
    opacity: 0,
    easing: 'ease-out',
    delay: 0,
    duration: 300,
    reset: true
  });

  sr.reveal('.sr--top', {
    origin: 'top',
    distance: '50px',
    scale: 0,
    opacity: 0,
    easing: 'ease-out',
    delay: 0,
    duration: 500,
    reset: true
  });

  sr.reveal('.sr--left', {
    origin: 'left',
    distance: '10px',
    opacity: 0,
    duration: 1500,
    delay: 300,
    scale: 1,
    // easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)',
    reset: true
  });

  sr.reveal('.sr--right', {
    origin: 'right',
    distance: '10px',
    opacity: 0,
    duration: 1500,
    delay: 300,
    scale: 1,
    // easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)',
    reset: true
  });

  sr.reveal(
    '.sr--steps .sr--step',
    {
      origin: 'top',
      distance: '50px',
      scale: 0,
      opacity: 0,
      easing: 'ease-out',
      delay: 500,
      duration: 500,
      reset: true
    },
    50
  );

  $('.has-videos').fitVids()

})
